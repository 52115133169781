<template>  

    <div @click="hideNotify" v-if="notification" 
            class="max-w-[1200px] md:w-[80%] p-2 md:p-5 mx-auto fixed 
            max-md:bottom-24 md:bottom-5 left-3 border bg-white opacity-90 right-3 text-center z-[100] shadow-2xl rounded-[12px] md:rounded-[24px]"
            :class="{
            'text-green-600 border-green-600': notification.type === 'success',
            'text-red-600 border-red-600': notification.type === 'error',
            'text-yellow-600 border-yellow-600': notification.type === 'warning',
            'text-blue-600 border-blue-600': notification.type === 'info'
            }">
        {{ notification.message }}
    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    // props: {
    //     notification: {
    //         type: Object,
    //         required: false,
    //         default: null
    //     }
    // },
    // data() {
    //     return {
    //         showNotify: true
    //     }
    // },
    computed: {
        ...mapState(['notification']),
    },
    methods: {
        hideNotify() {
            this.$store.dispatch('hideNotify');
            this.notification = false;
        }
    }
}

</script>