<template>

<div v-if="isQuizDone" class="flex-grow flex flex-col ">

    <div class="md:mt-8 flex flex-col md:flex-row max-w-[1200px] md:w-[90%] mx-auto flex-grow max-md:w-full">

        <div class="w-full md:w-[200px] max-md:h-16 z-30 max-md:pr-3">

            <div class="flex md:flex-col flex-row max-md:justify-between mx-3 md:top-5 md:sticky w-full">

                <div class="my-3 md:my-5 md:pb-4 flex flex-col">
                    <router-link to="/" class="mb-4 max-md:hidden"><img src="@/assets/logo.png" class="w-24 md:w-28"></router-link>
                    <router-link to="/" class="mb-4 md:hidden"><img src="@/assets/logo-icon.png" class="h-10"></router-link>
                </div>

                <div class="mt-3 md:mt-5 max-md:mx-auto flex flex-row md:flex-col gap-5 mr-3 md:bottom-10 md:fixed ">
                    <div class="max-mi:hidden flex flex-row gap-0">
                        <img src="@/assets/set/dropcoin.svg" class="w-10 h-10 opacity-80 inline-block mr-1 mb-1">
                        <div class="flex flex-col text-base">
                            <div class="text-sm text-zinc-800 uppercase">DROP</div>
                            <div class="font-mono font-bold text-base">{{ userData?.balance_drop || '0' }}</div>
                        </div>
                    </div>
                    <div class="max-mi:hidden flex flex-row gap-0">
                        <img src="@/assets/set/usdtcoin.svg" class="w-10 h-10 opacity-80 inline-block mr-1 mb-1">
                        <div class="flex flex-col text-base">
                            <div class="text-sm text-zinc-800 uppercase">USDT</div>
                            <div class="font-mono font-bold text-base">{{ userData?.balance_usdt || '0.00' }}</div>
                        </div>
                    </div>
                </div>

                <div class="md:hidden flex flex-row gap-1 ml-auto p-3">
                    <router-link :to="{ name: 'about' }" class="b3bil p-1 px-2 h-10 w-12">
                        <img src="@/assets/set/help.svg" class="w-8 h-8 opacity-80 inline-block mr-1 mb-1">
                    </router-link>
                    <router-link :to="{ name: 'user' }" class="b3bir p-1 px-2 h-10 w-12">
                        <img src="@/assets/set/user.svg" class="w-8 h-8 opacity-80 inline-block mr-1 mb-1">
                    </router-link>
                </div>
                
            </div>
            
            <div v-if="isQuizDone" class="z-[50] md:pt-3 md:h-full 
                max-md:fixed min-w-[180px] max-md:bottom-0 max-md:left-0 max-md:right-0">

                <MainMenu class="md:top-28 md:sticky" :DEBUG="DEBUG" />

            </div>

        </div>
        <div class="md:w-auto flex-grow flex flex-col max-md:pb-[70px] max-md:pt-3 pb-5 max-w-[1000px] ">

            <main class="md:border-l flex flex-col flex-grow border-zinc-200 md:pr-0 md:pl-10 md:mt-8 overflow-x-hidden">
                <router-view :miniApp="miniApp" ></router-view>
            </main>

            <!-- <div class="border-t border-zinc-200 pt-5 mt-10 md:ml-10 max-md:mx-5"
            >

                <div class="flex flex-col md:flex-row gap-3">

                    <router-link :to="{ name: 'about' }" class="">
                        <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">How it works
                    </router-link>
                    
                    <a href="https://t.me/drophuntann" target="_blank" class="md:ml-auto">
                        <img src="@/assets/social/t-b.svg" class="w-5 h-5 mb-1 mr-2 inline-block opacity-70">Announcements
                    </a>
                    <a href="https://t.me/drophuntchat" target="_blank" class="">
                        <img src="@/assets/social/t-b.svg" class="w-5 h-5 mb-1 mr-2 inline-block opacity-70">Chat Group
                    </a>

                </div>

                <div class="mt-5 text-zinc-400 text-sm flex flex-row gap-4 max-md:mt-5"  :class="{ 'max-md:hidden': !isHome }">
                    <router-link :to="{ name: 'terms'}" class="text-zinc-400">Terms</router-link>
                    <router-link :to="{ name: 'feedback'}" class="text-zinc-400" >Feedback</router-link>
                </div>

            </div> -->

            </div>
        </div>

    </div>

<div v-else>
    <Quiz @quiz-done="setQuizDone" />
</div>

</template>

<script>
import { mapState, mapActions } from 'vuex';

import MainMenu from '@/components/MainMenu.vue';
import UserProfile from '@/components/UserProfile.vue';
import Quiz from '@/pages/Quiz.vue';

export default {

    components: {
        MainMenu,
        UserProfile,
        Quiz,
    },

    props: {
        miniApp: {
            type: Boolean,
            default: false,
        },
    },
 
    data() {
        return {

            isLoading: false,
            isQuizDone: false,

        }
    },
    
    computed: {
      
        ...mapState(['userData']),
        // isAuth() { return !!this.user; },
     
        ...mapState(['walletAddr', 'walletData', 'walletConnected', 'walletConnecting']),

    },
    mounted() {

        this.getQuizStatus();

    },
    methods: {

        async getQuizStatus() {
            // const response = await api.post('quiz/status');
            // return response.data.data;
            if(this.userData.quiz_status?.quiz_done) {
                this.setQuizDone();
            }
        },

        setQuizDone() {
            this.isQuizDone = true;
        },

        ...mapActions(['doConnect', 'doDisconnect']), // ?
        ...mapActions(['signWallet', 'disconnectWallet', 'connectWallet']),

        ...mapActions('auth', ['logout']),

    }, 

}
</script>
