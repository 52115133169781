<template>

    <div class="flex flex-col bg-white max-md:border-t max-md:border-zinc-300 max-md:pt-3
        max-md:flex-row p-2 md:px-5 md:gap-5 md:text-lg 
        max-md:text-sm
        max-md:items-center max-md:text-center text-base">
        <!-- max-md:bg-[url('@/assets/menu-bg.png')] bg-cover bg-center bg-no-repeat -->
        <!-- <router-link :to="{ name: 'list' }"   :class="{ 'c1': isActiveTab('list') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/earth.svg" class="w-7 h-7 mx-auto">Coins</router-link> -->
        <!-- <router-link :to="{ name: 'city' }"   :class="{ 'c1': isActiveTab('city') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/city.svg" class="w-7 h-7 mx-auto">Cities</router-link> -->
        <!-- <img src="@/assets/set/lock.svg" class="w-4 h-4 opacity-35 mb-1 inline-block"> -->
        <router-link :to="{ name: 'wallet' }"   :class="{ 'c1': isActiveTab('wallet') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/wallet.svg" class="w-7 h-7 mx-auto">Wallet</router-link><!-- Wallet -->
        <router-link :to="{ name: 'task' }"   :class="{ 'c1': isActiveTab('task') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/hunt.svg" class="w-7 h-7 mx-auto"><div class="flex flex-row gap-2 mx-auto"><div class="my-auto">Hunt</div><span v-if="userData?.tasks_available > 0" class="bg-red-400 text-white px-3 py-1 rounded-full text-sm font-bold">{{ userData?.tasks_available }}</span></div></router-link><!-- bow.svg --->
        <router-link :to="{ name: 'list' }"   :class="{ 'c1': isActiveTab('list') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4 opacity-50"><img src="@/assets/set/hub.svg" class="w-7 h-7 mx-auto"><div>Drops</div></router-link>
        <router-link :to="{ name: 'bags' }" :class="{ 'c1': isActiveTab('bags') }" class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4 opacity-50"><img src="@/assets/set/backpack.svg" class="w-7 h-7 mx-auto"><div>Bags</div></router-link>
        <router-link :to="{ name: 'invite' }" :class="{ 'c1': isActiveTab('invite') }" class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/users.svg" class="w-7 h-7 mx-auto">Friends</router-link>
        <router-link :to="{ name: 'user' }"   :class="{ 'c1': isActiveTab('user') }" class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4 max-md:hidden"><img src="@/assets/set/profile.svg" class="w-7 h-7 mx-auto">Profile</router-link>
        <router-link :to="{ name: 'about' }"   :class="{ 'c1': isActiveTab('about') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4 max-md:hidden"><img src="@/assets/set/book.svg" class="w-7 h-7 mx-auto">Help</router-link>
    
    </div>

</template>

<script>

 export default {

    props: { },

    data() {
        return {

            DEBUG: false,
 
        }
    }
}
</script>