export default {
    data() {

        return {

            Language: [
                { name: 'English', code: 'en', flag: '🇺🇸' },
                { name: 'Spanish', code: 'es', flag: '🇪🇸' },
                { name: 'French', code: 'fr', flag: '🇫🇷' },
                { name: 'German', code: 'de', flag: '🇩🇪' },
                { name: 'Italian', code: 'it', flag: '🇮🇹' },
                { name: 'Portuguese', code: 'pt', flag: '🇵🇹' },
                { name: 'Russian', code: 'ru', flag: '🇷🇺' },
                { name: 'Chinese', code: 'zh', flag: '🇨🇳' },
                { name: 'Japanese', code: 'ja', flag: '🇯🇵' },
                { name: 'Korean', code: 'ko', flag: '🇰🇷' },
            ],
          
        }

    },
    methods: {

        checkEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
            .then(() => {
                // console.log('Text copied to clipboard');
            })
            .catch(err => {
                // console.error('Failed to copy text: ', err);
            });
        },
        
        isActiveTab(tabName) {
            return this.$route.name === tabName;
        },

        truncateName(name) {
            if (typeof name === 'string') {
                return name.length > 20 ? name.substring(0, 20) + '...' : name;
            } else {
                return name;
            }
        },

        roundTo(num=0) {
            return Math.round(this.coin.key_price_growth,num);
        },

        getImage(img) {
            return require('@/assets/' + img);
        },

        formatWallet (walletaddress, letters = 6) {

            if(walletaddress) { 
                // if(wallet.>40) {
                if(letters>30 || letters<2 || !letters) letters=6;
                return walletaddress.substring(0, (letters))+'...'+walletaddress.substr((walletaddress.length-letters));
                //} else return wallet;
            }
            return '';

        },

        // shortenAddress(address) {
        //     return `${address.slice(0, 6)}...${address.slice(-4)}`;
        // },
        
        formatDate(date) {
            // Create a new Date object for the current date
            const today = new Date();
            // Create a new Date object for the given date
            const givenDate = new Date(date);
            // Calculate the difference in milliseconds
            const diffInMs = Math.abs(today - givenDate);
            // Convert the difference from milliseconds to days, hours, and minutes
            const diffInDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000));
            const diffInHours = Math.floor(diffInMs / (60 * 60 * 1000));
            const diffInMinutes = Math.floor(diffInMs / (60 * 1000));

            // Return the difference in days, hours, or minutes
            if (diffInDays > 0) {
                return diffInDays + ' days';
            } else if (diffInHours > 0) {
                return diffInHours + ' hours';
            } else {
                return diffInMinutes + ' minutes';
            }
        },

        formatTON(nanotons,num=4) {
            // Convert nanotons to TON and format
            return (nanotons / 1000000000).toFixed(num) + ' TON';
        },

        formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },

        formatMC(num) {
            if (typeof num === 'undefined' || num === null) {
                return '—';
            }
            if (num >= 1e9) {
                return '$'+(num / 1e9).toFixed(1) + 'B';
            } else if (num >= 1e6) {
                return '$'+(num / 1e6).toFixed(1) + 'M';
            } else if (num >= 1e3) {
                return '$'+(num / 1e3).toFixed(1) + 'K';
            } else {
                return '$'+num.toString();
            }
        },

        tierPoints(keyRate) {
            if (keyRate === 1) return 15000;
            if (keyRate === 2) return 10000;
            if (keyRate === 3) return 5000;
            if (keyRate === 4) return 2500;
            if (keyRate === 5) return 1500;
        },

        getTier(keyRate,text=true) {
            let tier, tierName, colors;
      
            if (keyRate === 1) { tier = 1; colors = 'bg-red-100 text-red-800 border-red-300'; }
            else if (keyRate === 2) { tier = 2; colors = 'bg-green-100 text-green-800 border-green-300'; }
            else if (keyRate === 3) { tier = 3; colors = 'bg-orange-100 text-orange-800 border-orange-300'; }
            else if (keyRate === 4) { tier = 4; colors = 'bg-blue-100 text-blue-800 border-blue-300'; }
            else if (keyRate === 5) { tier = 5; colors = 'bg-yellow-100 text-yellow-800 border-yellow-300'; }
            else { tier = 0; colors = 'bg-gray-100 text-gray-800 border-gray-300'; }
      
            if(text===true)  tierName = tier === 0 ? 'Unknown' : `Tier ${tier}`;
            else tierName = tier === 0 ? '-' : `${tier}`;
      
            return `<span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium border ${colors}">${tierName}</span>`;
        },
        

    }
}