<template>

  <div class="min-h-screen p-4 flex flex-col overflow-hidden relative">
    <!-- <div class="b-g-500 absolute left-[10%] top-[-20px] z-10"></div> -->
    <div class="border border-zinc-400 rounded-xl p-4 px-6 flex-grow relative">
      
      <div class="absolute z-[1] left-0 top-0 w-full h-full bg-gradient-to-t from-green-200 to-yellow-50 opacity-50"></div>

      <div class="relative z-[10]">
        <img src="@/assets/logo.png" class="w-24 md:w-28 mb-5 mx-auto">
        <div class="quiz-container max-w-2xl mx-auto text-center z-[10]">
          <!-- Progress Bar -->
          <div class="progress-bar flex justify-between mb-5 rounded-xl overflow-auto md:py-5">
            <div
              v-for="(step, index) in steps"
              :key="index"
              :class="['progress-step', { active: index < currentStep - 1 }]"
            ></div>
          </div>
          <div class="progress-text mb-5" v-if="currentStep > 0 && currentStep <= steps.length">
            Step {{ currentStep }} of {{ steps.length }}
          </div>
          <!-- Initial Slide -->
          <div class="quiz-content" v-if="currentStep === 0">
            <h5 class="text-xl font-bold mb-5 farro">Start Your Adventure</h5>
            <div class="py-1">
              <img src="@/assets/spot1.svg" class="inline w-[75%] max-w-[420px] mb-5">
            </div>
            <div class="mb-3 text-xl text-slate-700 farro">
              Earn $DROP
              <!-- and $DROP  -->
              doing daily challenges,
              withdraw USDT directly to your TON wallet!
        
            </div>
            <!-- <div class="farro text-xl text-slate-700 mb-5">
        
              </div> -->
            <!-- <div class="mb-5 text-lg font-bold">1000 $DROP = <img src="@/assets/net/usdt.svg" class="inline w-6 mb-1 h-6" /> 0.1 USDT</div> -->
            <!-- 100K $DROP = $10 -->
            <!-- 1M $DROP = $100 -->
            <!-- 100 $DROP = $0.01 -->
            <!-- <div>Choose your language:</div> -->
            <!-- Language Chooser -->
            <div class="mb-5">
              <label for="language-select" class="block mb-2 text-sm uppercase c2">Preferred language:</label>
              <select
                id="language-select"
                v-model="selectedLanguage"
                @change="selectLanguage"
                class="w-fit mx-auto p-2 border border-gray-300 rounded bg-white text-lg"
              >
                <!-- <option value="" disabled>Select a language</option> -->
                <option
                  v-for="(language, index) in Language"
                  :key="index"
                  :value="language.code"
                >
                  {{ language.flag }}&nbsp; {{ language.name }}
                </option>
              </select>
              <!-- <div class="text-sm text-gray-500 mt-2">You can change it later in Settings.</div> -->
            </div>
            <div class="mb-3 text-lg">
              Take a quick survey to help us provide tasks tailored to your interests:
              <!-- this will help us offer your tasks matching your interests. -->
              <!-- the service experiens and tasks to be offered. -->
            </div>
            <button @click="startQuiz" class="b2 bc w-full">
              Start
            </button>
            <div class="mt-4">
              You will get
              <img src="@/assets/dropcoin.svg" class="inline w-5 h-5 mb-1 mr-1" />
              <span class="font-bold">100 $DROP</span>
              for completing the quiz.
            </div>
        
            <div class="text-xs text-gray-500 mt-5">By starting you agree to <router-link :to="{ name: 'terms' }">Terms of Service</router-link>.</div>
          </div>
          <!-- Quiz Content -->
          <div class="quiz-content" v-else-if="currentStep <= steps.length">
             <!-- Loader -->
             <div v-if="isLoading" class="mt-4">
              <div class="loader"></div>
              <p class="mt-2">Loading...</p>
            </div>
            <div v-else>
              <h2 class="text-2xl font-bold mb-5">{{ steps[currentStep - 1].question }}</h2>
              <div class="answers">
                <button
                  v-for="(answer, index) in steps[currentStep - 1].answers"
                  :key="index"
                  @click="nextStep(index + 1)"
                  class="block w-full my-2 py-2 px-4 bg-gray-200 border border-gray-300 rounded hover:bg-gray-300"
                  :disabled="isLoading"
                >
                  {{ answer.text }}
                </button>
              </div>
            </div>
          </div>
          <!-- Final Slide -->
          <div v-if="quizDone || currentStep > steps.length" class="flex flex-col gap-3 text-lg">
            <h2 class="text-2xl font-bold mb-3">Congrats, you've earned your first $DROP!</h2>
            <div class="text-3xl c1 border border-red-400 rounded-xl p-3">
              <img src="@/assets/dropcoin.svg" class="inline w-8 h-8 mb-1 mr-1" />
              <span class="font-bold">100 $DROP</span>
              <!-- for completing the quiz. -->
            </div>
            <div class="">for completing the quiz.</div>
            <div class="bg-orange-100 rounded-xl p-3">
              <div class="text-sm text-red-800 mb-2 uppercase">Starting Price</div>
              <div class="text-base"><b>1000 $DROP</b> = <img src="@/assets/net/usdt.svg" class="inline w-6 mb-1 h-6" /> <b>0.1 USDT</b></div>
            </div> 
            <div class="text-xl">
              Continue doing tasks and earn more $DROP before the launch!
            </div>
            <div class="text-base">
              Subscribe to the DropHunt social media not to miss the announcement on the official launch.
            </div>
            <button @click="setQuizDone()" class="b3 bc">
              Start the Hunt
            </button>
            <!-- <div>
              Invite friends to get the maximum tranction on when the project launched.
            </div> -->
            <!-- <button @click="retryQuiz" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">
              Retry
            </button> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapState } from 'vuex';

export default {
  props: ['quiz-done'],
  data() {
    return {
      currentStep: 0,
      steps: [
        { // 1
          question: "How often do you use Telegram?",
          answers: [
            { text: "Every minute" },
            { text: "Couple times a day" },
            { text: "Once a week" },
            { text: "Rarely" },
          ],
        },
        { // 2
          question: "Do you have an X (Twitter) account?",
          answers: [
            { text: "Yes, frequently" },
            { text: "Yes, rarely" },
            { text: "No, but I can create one" },
            { text: "No, and will never have" },
          ],
        },
        { // 3
          question: "What are your main interests?",
          answers: [
            { text: "Cryptocurrency" },
            { text: "Technology & Gadgets" },
            { text: "Games" },
            // { text: "Software Development" },
            { text: "Design & Fashion" },
            { text: "Food, Health & Fitness" },
            { text: "Politics" },
            { text: "Travel" },
            // { text: "Art" },
          ],
        },
        { // 4
          question: "Do you watch YouTube?",
          answers: [
            { text: "Yes, commenting on it" },
            { text: "Yes, regularly" },
            { text: "Yes, but rarely" },
            { text: "Not at all" },
          ],
        },
        { // 5
          question: "Can we ask you to invite friends?",
          answers: [
            { text: "Yes, I can bring a lot of referrals" },
            { text: "I can invite a couple of friends" },
            { text: "Only if you prove the service successful" },
            { text: "I am KOL / Influencer / Channel Owner" },
            { text: "No, it's a bad idea" },
          ],
        },
                // {
        //   question: "Do you have Discord?",
        //   answers: [
        //     { text: "Yes" },
        //     { text: "Yes, but not using it much" },
        //     { text: "No, but I can create one" },
        //     { text: "No, and will never have" },
        //   ],
        // },
      ],
      quizDone: false,
      answers: [],
      selectedLanguage: 'en',
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['userData']),
  },
  mounted() {
      // Set selectedLanguage based on userData.language
      if (this.userData && this.userData.language) {
        const languageExists = this.Language.find(lang => lang.code === this.userData.language);
        if (languageExists) {
          this.selectedLanguage = this.userData.language;
        }
      }
  },
  methods: {
    async startQuiz() {
      const status = await this.getQuizStatus();
      this.currentStep = status.quiz_step + 1;
      this.answers = status.answers;
      this.quizDone = status?.quiz_done;
    },
    async nextStep(answerIndex) {
      this.isLoading = true;
      try {
        await this.submitAnswer(this.currentStep, answerIndex);
        this.answers[this.currentStep] = answerIndex;
        if (this.currentStep < this.steps.length) {
          this.currentStep++;
        } else {
          this.quizDone = true;
          this.currentStep++; // Add this line to move to the final slide
        }
      } catch (error) {
        console.error('Error submitting answer:', error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        this.isLoading = false;
      }
    },
    async submitAnswer(step, answer) {
      try {
        await api.post('quiz/submit', {
          data: {
            step: step,
            answer: answer
          }
        });
      } catch (error) {
        console.error('Error submitting answer:', error);
      }
    },
    async getQuizStatus() {
      try {
        const response = await api.post('quiz/status');
        return response.data.data;
      } catch (error) {
        console.error('Error getting quiz status:', error);
        return { quiz_step: 0, quiz_done: false, answers: {} };
      }
    },
    setQuizDone() {
      this.$emit('quiz-done');
    },
    retryQuiz() {
      this.currentStep = 0;
      this.quizDone = false;
      this.answers = [];
      this.startQuiz();
    },
    selectLanguage() {
      // Implement language selection logic here
      console.log(`Selected language: ${this.selectedLanguage}`);
      // You can add more logic here, such as updating the UI or making an API call
    },
  },
  async created() {
    try {
      const response = await api.post('quiz/status');
      if (response.data.status === 'success' && response.data.data.quiz_done) {
        // alert('Quiz already completed, skip to the end or handle accordingly');
        // Quiz already completed, skip to the end or handle accordingly
        this.currentStep = this.steps.length + 1;
      }
    } catch (error) {
      console.error('Error checking quiz status:', error);
    }
  },
};
</script>

<style scoped>
.progress-step {
  width: 20%;
  height: 10px;
  background-color: #e0e0e0;
}

.progress-step.active {
  background-color: #3b82f6;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>